import React from 'react';
import { Grid } from '@mui/material';
import araji from './Web 1920 – 13/Mask Group 31.png';
import arajiImg from './Web 1920 – 13/Mask Group 32.png';
import datums from './Web 1920 – 13/Mask Group 33.png';
import './App.scss';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={1}>
            <img
              className="Resizeable-image"
              style={{ maxWidth: '50%' }}
              src={araji}
              alt="arāji"
            />
          </Grid>
          <Grid item xs={12}>
            <img
              className="floating-animation Resizeable-image"
              alt="ilustrācija"
              src={arajiImg}
            />
          </Grid>
          <Grid item xs={1}>
            <img
              className="Resizeable-image"
              style={{ maxWidth: '50%' }}
              src={datums}
              alt="datums"
            />
          </Grid>
        </Grid>
        {/* <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}
      </header>
    </div>
  );
}

export default App;
